import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import NET from "vanta/dist/vanta.net.min";
import './barocdeCss.css'
const MyComponent = () => {
  const vantaRef = useRef(null); // Ref for the DOM element
  const vantaEffect = useRef(null); // Ref to store the Vanta effect instance

  useEffect(() => {
    // Initialize VANTA.NET
    if (!vantaEffect.current) {
      vantaEffect.current = NET({
        el: vantaRef.current, // Reference the DOM element
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 0.1,
        scaleMobile: 1.0,
        color: 0xffffff,
        backgroundColor: 0x854857,
        showDots: false,
        THREE, // Pass the imported THREE library
      });
    }
    return () => {
      // Clean up the effect when the component is unmounted
      if (vantaEffect.current) {
        vantaEffect.current.destroy();
        vantaEffect.current = null;
      }
    };
  }, []);

  return (
    <div
      ref={vantaRef} // Attach the ref to the div
      style={{ width: "100vw", height:'100vh' }}
      className="vantaDiv"
    />
  );
};

export default MyComponent;
