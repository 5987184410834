import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import './barocdeCss.css'
import VantaNetBackground from './VantaNetBackground';
import axios from 'axios'
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';
import { notification } from 'antd';
import logo from './jjsRegistration/images/the.png'
const VisitorBarcodeScanning = () => {
  const { pathname } = useLocation();
  const [show_otp_page, set_show_otp_page] = useState(false)
  const [barcode, set_barcode] = useState(false)
  const [timer, setTimer] = useState(5); // Timer starts from 5 seconds


  const [otp, setOtp] = useState(Array(4).fill(''));
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [resendMessage, setResendMessage] = useState('');
  const [data, set_data] = useState(null)
  const [show_card, set_show_card] = useState(false)
  const contentRef = useRef(null);
  const [printHit, setPrintHit] = useState(false);
  const [final_otp, set_final_otp] = useState(null)

  const inputRefs = useRef([]);

  const handlePrint = useReactToPrint({
    contentRef
  });
  useEffect(() => {
    if (!printHit && contentRef.current) {
      setPrintHit(true)
      handlePrint()
      console.log("contentRef", contentRef)
    }
  }, [data])

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) { // Ensure only a single digit
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Log OTP as a concatenated string
      console.log(newOtp.join(''));
      set_final_otp(newOtp.join(''))
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };



  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      if (!otp[index]) {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
          newOtp[index - 1] = '';
        }
      } else {
        newOtp[index] = '';
      }
      setOtp(newOtp);
    }
  };

  const verifyOTP = () => {

    const formData = new FormData();

    formData.append('barcode', barcode)
    formData.append('otp', final_otp)



    axios.post('https://jjs.samaksh.in/api/visitor/v2/verify-kiosk-otp',
      formData
    )
      .then((response) => {
        setResendDisabled(true);
        setResendAttempts(prev => prev + 1);
        if (resendAttempts >= 1) {
          setResendMessage('Try again later.');
        }

        set_show_card(true)
        set_show_otp_page(false)

        set_data(response.data.visitor['0'])
        console.log(JSON.stringify(response.data.visitor['0']));
      })
      .catch((error) => {
        notification.success({ message: 'Verification Failed', description: 'Wrong Otp' })
     
          // Reset OTP and move focus to the first input
          setOtp(Array(otp.length).fill(''));
          inputRefs.current[0].focus();
        console.log(error);
      });

  };

  useEffect(() => {
    console.log("sfsd", resendAttempts)
    if (resendAttempts < 2) {
      setTimer(5)
    }
  }, [resendAttempts])
  const resendOTP = () => {
    setTimer(5)

    if (resendAttempts < 2 && timer === 0) {


      const formData = new FormData();
      formData.append('barcode', barcode)

      axios.post('https://jjs.samaksh.in/api/visitor/v2/re-send-kiosk-otp',
        formData
      )
        .then((response) => {
          notification.success({ message: 'Otp Resend Successfully', description: '' })
          setOtp(Array(4).fill(''))
          inputRefs.current[0].focus();

          set_final_otp(null)
          setResendAttempts(prev => prev + 1);
          if (resendAttempts < 2) {
            setResendDisabled(true); // Disable link until next timer ends
            setResendMessage(""); // Clear previous message
          } else {
            setResendMessage("Try after some time"); // Show message after 2 attempts
          }
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
          notification.error({ message: 'Problem in sending Otp', description: '' })

        });
    } else {
      setResendMessage('Resend blocked. Try again later.');
    }
  };

  // Countdown timer
  useEffect(() => {
    if (timer > 0 && resendAttempts < 2) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval); // Cleanup interval on unmount
    } else if (timer === 0) {
      setResendDisabled(false); // Enable resend link once timer hits 0
    }
  }, [timer, resendAttempts]);


  useEffect(() => {
    if (barcode && barcode.length === 6) {


      const formData = new FormData();
      formData.append('id', barcode)

      axios.post('https://jjs.samaksh.in/api/visitor/v2/kiosk-otp',
        formData
      )
        .then((response) => {
          console.log(response.data.msg);
          if (response.data.msg === "OTP Sent") {
            notification.success({ message: 'Otp Send Successfully', description: '' })
            setTimer(5)
            set_show_otp_page(true)
          } else {
            notification.error({ message: 'Barcode is invalid', description: '' })
          }
        })
        .catch((error) => {
          set_show_otp_page(true)

          console.log(error);
        });
    }
  }, [barcode])

  useEffect(() => {
    if (final_otp && final_otp?.length === 4) {

      verifyOTP()
    }
  }, [final_otp])

  return (
    <div className='barcode-text-scanning'>
      <img src={logo} alt="Show Logo" style={{
        height: '100px',
        width: '200px',
        background: 'none',
        zIndex: '999999999',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        margin: '0 auto',

      }} className='mt-5 imagelog' />
      <VantaNetBackground />
      <div>

        <div className='mt-5'>



          {show_otp_page ? (<div className='otp-div mt-5' style={{
            position: 'absolute',
            top: '20%',
            left: '0px',
            right: '0px',
            margin: '0 auto',
            width: 'max-content',
            textAlign: 'center'
          }}>

            <div className="p-5" style={{ backgroundColor: '#fff' }}>
              <h2 className="text-dark">OTP Verification</h2>
              <p className='mt-5'>Enter the 4-digit code sent to your device</p>
              <div className="otp-input">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    value={digit}
                    maxLength="1"
                    required
                    ref={el => (inputRefs.current[index] = el)}
                    onChange={e => handleChange(e, index)}
                    onKeyDown={e => handleBackspace(e, index)}
                  />
                ))}
              </div>
              <div>
                <a
                  onClick={resendOTP}
                  disabled={timer > 0 || resendAttempts >= 2}
                  style={{ cursor: timer > 0 || resendAttempts >= 2 ? "not-allowed" : "pointer" }}
                >
                  Resend Code
                  {timer > 0 && resendAttempts <= 2 && <span> ({timer}s)</span>}
                </a>
                {resendMessage && <p>{resendMessage}</p>}
              </div>
            </div>
          </div>) : show_card ? (

            <div class="form-container mt-5"
              style={{
                position: 'absolute',
                top: '20%',
                left: '0px',
                right: '0px',
                margin: '0 auto',
                width: 'max-content'
              }}
            >


              <section id="wizard-p-1" role="tabpanel" className="body current no-print" ref={contentRef} >
                <div className="form-row" style={{ display: 'flex', justifyContent: 'center' }}>
                  <div id="printable-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="imge-section">
                      <img
                        src={data?.photo}
                        style={{
                          margin: '0 auto',
                          position: 'absolute',
                          top: '160px',
                          height: '110px',
                          width: '100px',
                          left: '0px',
                          right: '0px',
                          objectFit: 'contain'
                        }}
                        alt=""
                      />
                      <div
                        className="id-text"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          left: 0,
                          right: 0,
                          bottom: '120px'
                        }}
                      >
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <h3 className="mt-1" style={{ fontSize: '12px', color: '#000' }}>{data?.name}</h3>
                        </div>
                        <span style={{ fontSize: '12px', color: '#000' }}>{data?.city}</span>
                        <div style={{ height: '10px' }}>
                          {data?.barcode && (
                            <Barcode
                              style={{ background: "#ffffff" }}
                              value={data?.barcode}
                              format="CODE128"
                              width={2}
                              height={35}
                              displayValue={true}
                            />
                          )}
                          <div className="text-center">
                            <h4 className="mt-3" style={{ color: '#000', fontSize: '20px', textAlign: 'center' }}>{data?.category}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

          ) : (<div class="form-container mt-5"
            style={{
              position: 'absolute',
              top: '20%',
              left: '0px',
              right: '0px',
              margin: '0 auto',
              width: 'max-content'
            }}
          >

            <h2 className='mb-5'>{pathname.split('/')[2] === 'v' ? 'Visitor Barcode Scanning' : 'Trade Visitor Barcode Scanning'}</h2>
            {/* <form id="trialForm" novalidate> */}

              <input type="email" id="email" name="email" placeholder="Scan Your QR Code" required onChange={(e) => set_barcode(e.target.value)} className='mt-5'/>
              <div id="error-message"></div>
             
              <div id="success-message"></div>

            {/* </form> */}
          </div >)
          }
        </div>
      </div >

    </div>

  )
}

export default VisitorBarcodeScanning
