import React, { useState, useEffect, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import axios from 'axios';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';
import { Descriptions, notification } from 'antd';
const BarcodeScanning = () => {
    const [qrResult, setQrResult] = useState(null);
    const [qrTextResult, setQrTextResult] = useState(null);

    const [name, setName] = useState('John Doe');
    const [city, setCity] = useState('New York');
    const [inputValue, setInputValue] = useState('123456789');
    const [visitor, setVisitor] = useState('Visitor');
    const qrCodeRef = useRef(null);
    const html5QrCodeRef = useRef(null);
    const [data, setData] = useState(null);
    const contentRef = useRef(null);
    const reactToPrintRef = useRef(null);
    const [hasFetchedData, setHasFetchedData] = useState(false);
    const [printHit, setPrintHit] = useState(false);
    const [showScanningScreen, setShowScanningScreen] = useState(false)
    const handlePrint = useReactToPrint({
        contentRef
    });
    useEffect(() => {
        if (!printHit && contentRef.current) {
            setPrintHit(true)
            handlePrint()
            console.log("contentRef", contentRef)
        }
    }, [data])

    useEffect(() => {
        const initializeQrCodeScanner = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ video: true });

                if (!html5QrCodeRef.current) {
                    html5QrCodeRef.current = new Html5Qrcode("reader");
                }

                const config = { fps: 10, qrbox: { width: 250, height: 250 } };

                html5QrCodeRef.current.start(
                    { facingMode: "environment" },
                    config,
                    (decodedText) => {
                        setQrResult(decodedText);
                        if (!hasFetchedData) {
                            setHasFetchedData(true); // Mark as fetched



                            axios.get(`${process.env.REACT_APP_API_END_POINT}/visitor-detail/${decodedText}`)
                                .then((response) => {
                                    if (response?.data?.data?.name) {
                                        setShowScanningScreen(true)
                                        setName(response?.data?.data?.name);
                                        setData(response?.data?.data);
                                        setCity(response?.data?.data?.city);
                                        setInputValue(response?.data?.data?.barcode);
                                        setVisitor(response?.data?.data?.category);
                                    } else {
                                        setShowScanningScreen(false)
                                        notification.error({
                                            message: 'Barcode is not valid',
                                            descriptions: ''
                                        })
                                    }

                                    // Automatically start printing once data is set
                                })
                                .catch((error) => {
                                    console.log("Error fetching visitor data:", error.message);
                                });
                        }
                        // Only hit the API if it hasn't been fetched yet

                    },
                    (errorMessage) => {
                        console.warn("QR Code scan error:", errorMessage);
                    }
                );
            } catch (err) {
                console.error("Camera access denied or unavailable:", err);
            }
        };

        initializeQrCodeScanner();

        return () => {
            if (html5QrCodeRef.current) {
                html5QrCodeRef.current.stop().then(() => {
                    console.log("QR Code scanning stopped.");
                    html5QrCodeRef.current = null;
                }).catch((err) => {
                    console.error("Failed to stop scanning:", err);
                });
            }
        };
    }, []);



    useEffect(() => {
        console.log("hello i am hfer", !hasFetchedData, qrTextResult, qrTextResult?.length === 5)
        if (qrTextResult?.length === 5) {
            setHasFetchedData(true); // Mark as fetched



            axios.get(`${process.env.REACT_APP_API_END_POINT}/visitor-detail/${qrTextResult}`)
                .then((response) => {
                    if (response?.data?.data?.name) {
                        setShowScanningScreen(true)
                        setName(response?.data?.data?.name);
                        setData(response?.data?.data);
                        setCity(response?.data?.data?.city);
                        setInputValue(response?.data?.data?.barcode);
                        setVisitor(response?.data?.data?.category);
                    } else {
                        setShowScanningScreen(false)
                        notification.error({
                            message: 'Barcode is not valid',
                            descriptions: ''
                        })
                    }

                    // Automatically start printing once data is set
                })
                .catch((error) => {
                    console.log("Error fetching visitor data:", error.message);
                });
        }
    }, [qrTextResult])
    // const handleInputChange = (e) => {
    //     const qrResult = e.target.value;
    //     const regex = /^[A-Za-z]{4}\d+$/; // 4 alphabets followed by 1 or more digits

    //     if (regex.test(qrResult)) {
    //         console.log("Valid QR Result:", qrResult);

    //         setQrResult(qrResult); // Update state if valid
    //     } else {
    //         notification.error({
    //             message: 'formate of barcode is invalid',
    //             descriptions: ''
    //         })
    //         console.error("Invalid QR Result format");
    //     }
    // };

    // const handlePrint = () => {
    //     document.body.classList.add('print-only');
    //     window.print();
    //     document.body.classList.remove('print-only');
    // };




    return (
        <div className="App">
            <h1 >JJS 2024 </h1>

            {showScanningScreen ? (
                <section id="wizard-p-1" role="tabpanel" className="body current no-print" ref={contentRef} >
                    <div className="form-row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div id="printable-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="imge-section">
                                <img
                                    src={data?.imageUrl}
                                    style={{
                                        margin: '0 auto',
                                        position: 'absolute',
                                        top: '160px',
                                        height: '110px',
                                        width: '100px',
                                        left: '0px',
                                        right: '0px',
                                        objectFit: 'contain'
                                    }}
                                    alt=""
                                />
                                <div
                                    className="id-text"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        bottom: '120px'
                                    }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <h3 className="mt-1" style={{ fontSize: '12px', color: '#000' }}>{name}</h3>
                                    </div>
                                    <span style={{ fontSize: '12px', color: '#000' }}>{city}</span>
                                    <div style={{ height: '10px' }}>
                                        {inputValue && (
                                            <Barcode
                                                style={{ background: "#ffffff" }}
                                                value={inputValue}
                                                format="CODE128"
                                                width={2}
                                                height={35}
                                                displayValue={true}
                                            />
                                        )}
                                        <div className="text-center">
                                            <h4 className="mt-3" style={{ color: '#000', fontSize: '20px', textAlign: 'center' }}>{visitor}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <>
                    <div
                        id="reader"
                        style={{
                            width: '100%',
                            maxWidth: '400px',
                            height: '400px',
                            margin: 'auto',
                            border: '2px solid black',
                            overflow: 'hidden',
                            position: 'relative'
                        }}
                    >

                    </div>
                    <div col='3'>

                        <input
                            name="dob"
                            type="text"
                            className="form-control mt-5"
                            value={qrTextResult || ""}
                            onChange={(e) => setQrTextResult(e.target.value)}
                            placeholder="Enter Barcode"
                            maxLength={6}

                            style={{
                                width: '100%',
                                maxWidth: '250px',
                                height: 'auto',
                                margin: 'auto',

                                position: 'relative'
                            }}


                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default BarcodeScanning;
